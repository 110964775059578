import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from '../../../layouts/grid.css'
import Heading from '../../Global/Heading'
import * as S from './Mission.css'
import * as G from '../../Global/Heading/Heading.css'

const Mission = ({ title, content, image01, image02, image03 }) => {
  return (
    <S.Mission>
      <Row gutter={20}>
        <Col xl={8}>
          <Row>
            <Col md={8}>
              <S.Box>
                <G.Heading2Size3 hasLine lineSize={5}>
                  {title}
                </G.Heading2Size3>
                <p>{content}</p>
              </S.Box>
            </Col>
            <Col md={4} image={image01}>
              <img src={image01} alt="" />
            </Col>
            <Col md={12} style={{ lineHeight: 0 }}>
              <img className="second-image" src={image02} alt="" />
            </Col>
          </Row>
        </Col>
        <Col xl={4} image={image03}></Col>
      </Row>
    </S.Mission>
  )
}

Mission.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image01: PropTypes.string,
  image02: PropTypes.string,
  image03: PropTypes.string,
}

Mission.defaultProps = {}

export default Mission
