import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors, media, shadows, transitions } from '../../../../lib/constants'

export const ClientCard = styled(Link)`
  margin-right: 1.5rem;
  text-align: center;
  background: ${colors.white};
  height: 11rem;
  width: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${shadows.projectCard};
  border-radius: 10px;
  margin-bottom: 2rem;
  border: solid 2px transparent;
  transition: border-color ${transitions.slow}, transform ${transitions.slow};
  transform: rotate(0deg);

  &:hover {
    border-color: ${colors.pink};
    transform: rotate(2deg);
  }

  &:nth-of-type(2n) {
    &:hover {
      transform: rotate(-2deg);
    }
  }

  img {
    max-width: calc(100% - 50px);
    max-height: calc(100% - 50px);
    margin-bottom: 0;
  }

  a:before {
    display: none;
  }

  ${media.tablet`
    width: 100%;
    height: 8rem;
    margin-bottom: 1.5rem;

    img {
      max-width: 70%;
      max-height: 7rem;
    }
  `}
`

export const Clients = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 2rem 0 1rem;

  ${media.tablet`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
  `}
`
