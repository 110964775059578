import React from 'react'
import { graphql } from 'gatsby'
import { Layout, Container } from '../layouts'
import { Helmet } from 'react-helmet'
import Hero from '../components/Global/Hero'
import Heading from '../components/Global/Heading'
import { colors } from '../../lib/constants'
import Staffs from '../components/Company/Staffs'
import CTA from '../components/Global/CTA'
import Mission from '../components/Company/Mission'
import Clients from '../components/Company/Clients'
import Testimonials from '../components/Company/Testimonials'
import Video from '../components/Global/Video'
import * as G from '../components/Global/Heading/Heading.css'

const CompanyPage = ({ data: { pageData, staff } }) => {
  const { clients, intro, cta, testimonials, featured_projects } =
    pageData.frontmatter
  const staffs = staff.edges
  return (
    <Layout>
      <Helmet>
        <title>
          Building software via relations and great ideas - TechEquipt
        </title>
        <meta
          name="description"
          content="TechEquipt believes software is about humans, we value people and relationships, considering everyone that is using and affected by the products we create. "
        />
      </Helmet>

      <Hero title={`The Company`} small />

      <br />
      <br />
      <br />

      <Container>
        <Mission
          title={intro.title}
          content={intro.content}
          image01={intro.image_01.publicURL}
          image02={intro.image_02.publicURL}
          image03={intro.image_03.publicURL}
        />
      </Container>

      {intro?.video?.url && (
        <Container padding="8rem 0 4rem">
          <Video
            url={intro.video.url}
            rounded
            thumbnail={intro?.video?.thumbnail?.publicURL || ''}
          />
        </Container>
      )}

      <Container>
        <G.Heading2Size3 hasLine mobileCenter lineSize={5} lineWidth={100}>
          Who's running this place?
        </G.Heading2Size3>
        <p className="mobile-text-center">
          TechEquipt is a company led by experienced management to provide
          clients both direction and results. We can assist in any number of
          areas as indicated in the process below:
        </p>
        <Staffs staffs={staffs} />
      </Container>

      <Container>
        <G.Heading2Size3 hasLine mobileCenter lineSize={5} lineWidth={100}>
          We're happy to work with...
        </G.Heading2Size3>
        <Clients clients={clients} />
      </Container>

      {testimonials && testimonials.length > 0 && (
        <Container color={colors.faded__blue}>
          <G.Heading2Size3 hasLine mobileCenter lineSize={5} lineWidth={100}>
            Testimonials
          </G.Heading2Size3>
          <Testimonials testimonials={testimonials} />
        </Container>
      )}

      {cta.title && cta.image.publicURL && (
        <Container>
          <CTA
            headingColor={colors.blue}
            bgColor={colors.white}
            heading={cta.title}
            content={cta.content}
            btnText={cta.button_text}
            btnLink={cta.button_link}
            image={cta.image.publicURL}
          />
        </Container>
      )}
    </Layout>
  )
}

export default CompanyPage

export const pageQuery = graphql`
  query CompanyPageTemplate {
    pageData: markdownRemark(
      frontmatter: { templateKey: { eq: "company-page" } }
    ) {
      frontmatter {
        slug
        templateKey
        intro {
          quote
          title
          content
          image_01 {
            publicURL
          }
          image_02 {
            publicURL
          }
          image_03 {
            publicURL
          }
          video {
            url
            thumbnail {
              publicURL
            }
          }
        }
        how_we_work {
          title
          content
          additional_information
          process {
            title
          }
        }
        featured_projects {
          title
          content
          projects {
            project {
              frontmatter {
                slug
                project_title
                feature_image {
                  publicURL
                }
                description
                client
              }
            }
          }
        }
        clients {
          link
          name
          colour
          logo {
            publicURL
          }
        }
        testimonials {
          client_name
          title
          content
          client_logo {
            publicURL
          }
        }
        cta {
          title
          content
          image {
            publicURL
          }
          button_text
          button_link
        }
      }
    }
    staff: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/staff/" } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            name
            position
            socials {
              linkedin
            }
            thumbnail {
              childImageSharp {
                fluid(maxHeight: 300, maxWidth: 300) {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  }
`
