import React from 'react'
import { Row, Col } from '../../../layouts/grid.css'
import * as S from './Staffs.css'
import * as G from '../../Global/Heading/Heading.css'

const Staffs = ({ staffs }) => {
  return (
    <S.StaffBoxes>
      <Row gutter={30} itemsCenter>
        {staffs.map((staff, index) => (
          <Col lg={4}>
            <S.StaffBox
              key={index}
              to={staff.node?.frontmatter?.socials?.linkedin}
              target="_blank"
              className="nounderline"
            >
              <G.Heading3Size4>{staff.node?.frontmatter?.name}</G.Heading3Size4>
              <S.StaffBoxText>
                {staff.node?.frontmatter?.position}
              </S.StaffBoxText>
            </S.StaffBox>
          </Col>
        ))}
      </Row>
    </S.StaffBoxes>
  )
}

export default Staffs
