import React from 'react'
import { Link } from 'gatsby'
import LazyLoad from 'react-lazyload'
import * as S from './Clients.css'

const Client = ({ data }) => {
  const logo = data.logo?.publicURL

  return logo ? (
    <S.ClientCard className="nounderline" to={data.link} target="_blank">
      <LazyLoad once offset={200}>
        <img src={logo} alt={data.name} />
      </LazyLoad>
    </S.ClientCard>
  ) : null
}

const Clients = ({ clients }) => {
  return (
    clients.length > 0 && (
      <S.Clients>
        {clients.map((it, key) => {
          return <Client key={key} data={it} />
        })}
      </S.Clients>
    )
  )
}

export default Clients
