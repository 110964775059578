import styled, { css } from 'styled-components'
import {
  colors,
  fonts,
  media,
  shadows,
  textMd,
  transitions,
} from '../../../../lib/constants'
import Heading from '../../Global/Heading'
import { Link } from 'gatsby'

export const Person = styled.article`
  padding: 0 10px;
`

export const PersonInner = styled.div`
  background: ${colors.faded__blue};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 0 0 50px;
`

export const PersonCircle = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 0 0 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 37px;

  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}
`

export const PersonDetails = styled.div`
  color: ${colors.blue};

  h3 {
    font-family: ${fonts.body};
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: 700;
    color: #333;
  }

  h5 {
    font-family: ${fonts.body};
    font-weight: 400;
  }
`

export const CarouselWrap = styled.div`
  margin: 0 -10px;
  padding-top: 2.5rem;

  .carousel.carousel-slider,
  .carousel .slider-wrapper {
    overflow: inherit;
  }

  .carousel .control-dots {
    bottom: -56px;
    margin: 0;

    .dot {
      margin-left: 0;
      margin-right: 10px;
      width: 10px;
      height: 10px;
      background: #c4c4c4;
      box-shadow: none;

      &.selected {
        background: ${colors.pink};
      }

      &:before {
        display: none;
      }
    }
  }
`

const controlStyle = css`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  margin-top: -25px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tablet`
    display: none;
  `}
`

export const PrevButton = styled.div`
  ${controlStyle}
  left: -26px;
`

export const NextButton = styled.div`
  ${controlStyle}
  right: calc(-200% - 26px);
`

export const StaffBox = styled(Link)`
  display: block;
  padding: 2rem;
  background: white;
  box-shadow: none;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto 3rem;
  box-shadow: ${shadows.projectCard};
  max-width: 450px;
  border: solid 2px transparent;
  transition: border-color ${transitions.slow};
  transform: rotate(0deg);

  &:hover {
    border-color: ${colors.pink};
  }

  p {
    color: ${colors.grey};
  }
`

export const StaffBoxTitle = styled(Heading)`
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`

export const StaffBoxText = styled.p`
  margin-bottom: 0;
  font-weight: 500;
`

export const StaffText = styled.p`
  margin-bottom: 0;
  padding: 1.5rem;
  background-color: ${colors.white};
  font-weight: 500;
  border-radius: 10px;
  ${textMd}
`

export const StaffBoxes = styled.section`
  margin-top: 4rem;

  & > div > div > a {
    transition: transform ${transitions.slow};
    &:hover {
      transform: rotate(0deg) !important;
    }
  }

  // first
  & > div > div:nth-child(1) > a {
    transform: rotate(-5deg);
  }
  // second
  & > div > div:nth-child(2) > a {
    transform: rotate(2deg);
  }
  // third
  & > div > div:nth-child(3) > a {
    transform: rotate(-3deg);
  }
  // fourth
  & > div > div:nth-child(4) > a {
    transform: rotate(3deg);
  }
  // fifth
  & > div > div:nth-child(5) > a {
    transform: rotate(-2deg);
  }
`
