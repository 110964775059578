import styled from 'styled-components'
import { media } from '../../../../lib/constants'

export const Mission = styled.div`
  padding-bottom: 4rem;

  img {
    margin-bottom: 0;
    width: 100%;

    &.second-image {
      margin-top: 1rem;
    }
  }
`

export const Box = styled.div`
  padding-right: 4rem;
  margin-bottom: 20px;

  h3,
  h2 {
    font-size: 40px;
    line-height: 1.15;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
  }

  ${media.tablet`
    text-align: center;
    padding-right: 0;

    h3:after {
      left: 50%;
      margin-left: -42px;
    }

    p {
      margin-bottom: 2.5rem;
    }
  `}
`
